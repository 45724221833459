import { BufferGeometry, Color, ColorSpace, Float32BufferAttribute, Line, LineBasicMaterial, LineSegments, Loader, LoadingManager, Material, Matrix4, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D, RepeatWrapping, Texture, TextureLoader, Vector2, Vector3 } from "three";
import { C3dFormat } from "./C3dTypes";

export class C3dWebLoader extends Loader {

  private textureLoader: TextureLoader;
  zDir = -1;

  constructor(manager?: LoadingManager) {
    super(manager);
    this.textureLoader = new TextureLoader();
  }

  public load(
    url: string,
    onLoad: (data: Object3D, header: {camosId: string, date: string}, views: {view1: string, view2: string, view3: string, view4: string}) => void,
    onProgress?: (event: ProgressEvent) => void,
    onError?: (event: ErrorEvent) => void,
    onTextureLoad?: () => void,
    onLayerLoad?: (layer: boolean[]) => void,
    onLanguageReceived?: (lang: string) => void,
    ) {
    
    fetch(url, {
      method: "GET",
    }).then(res => {
      if (!res.ok) return Promise.reject("Hallo");
      if (res?.ok) {
        res.json().then(json => {
          if (json.error && onError) {
            switch (json.error) {
              case "Id not found":
                onError(new ErrorEvent("notFoundId"));
                break;
              case "Incorrect id":
                onError(new ErrorEvent("wrongId"));
                break;
            }
          } else {
            if (onLanguageReceived) { onLanguageReceived(json.lang) }
            const result = this.parse(json.data, onTextureLoad);
            if (result) {
              onLoad(
                result.container,
                {camosId: json.internId ?? json.camosId, date: json.date},
                {view1: json.view1, view2: json.view2, view3: json.view3, view4: json.view4},
              );
              if (onLayerLoad) { onLayerLoad(result.layer) }
            }
          }
        });
      } else {
        if (onError) {
          onError(new ErrorEvent("http", { message: `${res?.status}` ?? ""}));
        }
      }
    });

    // FILE Load
    /*
    const loader = new FileLoader(this.manager);
    loader.setPath(this.path);
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);
    loader.load(url, (text: string | ArrayBuffer) => {
      const result = this.parse(text as string, onTextureLoad);
      onLoad(result.container);
      if (onLayerLoad) { onLayerLoad(result.layer) }
    });
    */
  }

  public loadFromString(
    jsonString: string,
    onLoad: (data: Object3D, header: {camosId: string, date: string}, views: {view1: string, view2: string, view3: string, view4: string}) => void,
    onTextureLoad?: () => void,
    onLayerLoad?: (layer: boolean[]) => void,
    onProgress?: (event: ProgressEvent) => void,
    onError?: (event: ErrorEvent) => void,
    onLanguageReceived?: (lang: string) => void,
  ) {
    const json = JSON.parse(jsonString);
    if (onLanguageReceived) { onLanguageReceived(json.lang) }
    const result = this.parse(json, onTextureLoad);
    onLoad(
      result.container,
      {camosId: json.internId ?? json.camosId, date: json.date},
      {view1: json.view1, view2: json.view2, view3: json.view3, view4: json.view4},
    );
    if (onLayerLoad) { onLayerLoad(result.layer) }
  }

  parse(obj: C3dFormat, onTextureLoad?: () => void) {
    const container = new Object3D();
    this.zDir = parseFloat(obj.version) < 2 ? -1 : 1;
    container.applyMatrix4(new Matrix4().scale(new Vector3(1, 1, this.zDir)));
    const textureMap = new Map<string, Texture>();
    const materialMap = new Map<string, Material>();
    const layer = Array.apply(null, Array(32)).map(() => false);
    let textureLoad = {count: obj.textures.length + 4, loaded: 0};

    // Tests if all textures are loaded yet
    const testLoad = () => {
      textureLoad.loaded += 1;
      if (textureLoad.count === textureLoad.loaded) {
        if (onTextureLoad) { onTextureLoad() }
      }
    }

    const getColorSpace = (value : number | ColorSpace) => {
      if (typeof value === "string") {
        return value;
      }
      return value === 3000 ? "srgb-linear" : "srgb";
    }

    // Textures
    for (const data of obj.textures) {
      const tex = this.textureLoader.load(data.path, testLoad, undefined, () => testLoad());
      tex.colorSpace = getColorSpace(data.encoding);
      if (data.offset != null) { tex.offset = new Vector2(data.offset[0], data.offset[1]) }
      if (data.repeat != null) { tex.repeat = new Vector2(data.repeat[0], data.repeat[1]) }
      if (data.rotation != null) { tex.rotation = data.rotation }
      if (data.wrap != null) {
        tex.wrapS = data.wrap[0];
        tex.wrapT = data.wrap[1];
      }
      textureMap.set(data.uuid, tex);
    }

    // Ground Textures
    const texGrassMap = this.textureLoader.load("data/diffuse/grass2_gamma1.jpg", testLoad);
    texGrassMap.wrapS = RepeatWrapping;
    texGrassMap.wrapT = RepeatWrapping;
    texGrassMap.repeat.set(0.2, 0.2);
    const texGrassBump = this.textureLoader.load("data/bump/grass.jpg", testLoad);
    texGrassBump.repeat.set(0.2, 0.2);
    const texAsphaltMap = this.textureLoader.load("data/diffuse/asphalt_gamma1.jpg", testLoad);
    texAsphaltMap.repeat.set(0.5, 0.5);
    texAsphaltMap.wrapS = RepeatWrapping;
    texAsphaltMap.wrapT = RepeatWrapping;
    const texAsphaltBump = this.textureLoader.load("data/bump/asphalt.jpg", testLoad);
    texAsphaltBump.repeat.set(0.5, 0.5);
    texAsphaltBump.wrapS = RepeatWrapping;
    texAsphaltBump.wrapT = RepeatWrapping


    // Materials
    for (const data of obj.materials) {
      const isStandard = data.type === "MeshStandardMaterial";
      const mat = isStandard ? new MeshStandardMaterial() : new MeshBasicMaterial();
      // General Props
      if (data.alphaMap != null) { mat.alphaMap = textureMap.get(data.alphaMap) ?? null }
      if (data.color != null) { mat.color = new Color(data.color) }
      if (data.map != null) { mat.map = textureMap.get(data.map) ?? null }
      if (data.opacity != null) { mat.opacity = data.opacity }
      if (data.polygonOffset != null) {
        mat.polygonOffset = true;
        mat.polygonOffsetFactor = 1;
        mat.polygonOffsetUnits = 1;
      }
      if (data.side != null) { mat.side = data.side }
      if (data.toneMapped != null) { mat.toneMapped = data.toneMapped }
      if (data.transparent != null) { mat.transparent = data.transparent }
      // Standard Material
      if (mat instanceof MeshStandardMaterial) {
        if (data.bumpMap != null) { mat.bumpMap = textureMap.get(data.bumpMap) ?? null }
        if (data.bumpScale != null) { mat.bumpScale = data.bumpScale }
        if (data.metalness != null) { mat.metalness = data.metalness }
        if (data.metalnessMap != null) { mat.metalnessMap = textureMap.get(data.metalnessMap) ?? null }
        if (data.normalMap != null) { mat.normalMap = textureMap.get(data.normalMap) ?? null }
        if (data.normalScale != null) { mat.normalScale = new Vector2(data.normalScale[0], data.normalScale[1]) }
        if (data.roughness != null) { mat.roughness = data.roughness }
        if (data.roughnessMap != null) { mat.roughnessMap = textureMap.get(data.roughnessMap) ?? null }
      }
      // Basic Material
      if (mat instanceof MeshBasicMaterial) {
        if (data.reflectivity != null) { mat.reflectivity = data.reflectivity }
        if (data.refractionRatio != null) { mat.refractionRatio = data.refractionRatio }
        if (data.specularMap != null) { mat.specularMap = textureMap.get(data.specularMap) ?? null }
      }
      materialMap.set(data.uuid, mat);
    }

    // Buildings
    for (const b of obj.buildings) {
      const building = new Object3D();
      building.name = b.name;

      // Meshes
      for (const data of b.mesh) {
        // Geometry
        const geo = new BufferGeometry();
        const vertices = data.vert ?? (data.vertC ? decodeVertices(data.vertC) : []);
        const uvs = data.uv ?? (data.uvC ? decodeUVs(data.uvC) : []);
        const pos = new Float32BufferAttribute(vertices, 3);
        const uv = new Float32BufferAttribute(uvs, 2);
        geo.setAttribute("position", pos);
        geo.setAttribute("uv", uv);
        geo.computeVertexNormals();
        if (data.groups != null) { geo.groups = data.groups }

        // Material and Mesh
        if (Array.isArray(data.material)) {
          const mat = [];
          for (const d of data.material) {
            const m = materialMap.get(d) ?? new Material();
            mat.push(m);
          }
          const mesh = new Mesh(geo, mat);
          mesh.matrixAutoUpdate = false;
          mesh.layers.set(data.layer ?? 0);
          if (data.name) { mesh.name = data.name }
          if (data.layer) { mesh.layers.enable(data.layer) }
          building.add(mesh);
        } else {
          const mat = materialMap.get(data.material ?? "") ?? new Material();
          const mesh = new Mesh(geo, mat);
          mesh.matrixAutoUpdate = false;
          const l = data.layer ?? 0;
          mesh.layers.set(l);
          layer[l] = true;
          if (data.name) { mesh.name = data.name }
          if (data.layer) { mesh.layers.enable(data.layer) }
          building.add(mesh);
        }
      }

      // Lines
      const lineMat = new LineBasicMaterial({color: 0x0});
      for (const data of b.lines) {
        // Geometry
        const geo = new BufferGeometry();
        const vertices = data.vert ?? (data.vertC ? decodeVertices(data.vertC) : []);
        const pos = new Float32BufferAttribute(vertices, 3);
        geo.setAttribute("position", pos);
        const line = data.type === "Line"
          ? new Line(geo, lineMat)
          : new LineSegments(geo, lineMat);
        if (data.layer) { line.layers.enable(data.layer) }
        const l = data.layer ?? 0;
        layer[l] = true;
        line.layers.set(l);
        building.add(line);
      }

      container.add(building);
    }

    // Ground
    if (obj.ground) {
      const data = obj.ground;
      // Geometry
      const vertices = data.vert ?? (data.vertC ? decodeVertices(data.vertC) : []);
      const uvs = data.uv ?? (data.uvC ? decodeUVs(data.uvC) : []);
      const pos = new Float32BufferAttribute(vertices, 3);
      const geo = new BufferGeometry();
      const uv = new Float32BufferAttribute(uvs, 2);
      geo.setAttribute("position", pos);
      geo.setAttribute("uv", uv);
      geo.computeVertexNormals();
      if (data.groups != null) { geo.groups = data.groups }

      // Material and Mesh
      const mat = [
        new MeshStandardMaterial({
          map: texGrassMap,
          bumpMap: texGrassBump,
          bumpScale: 0.05,
          metalness: 0,
          roughness: 1,
        }),
        new MeshStandardMaterial({
          map: texAsphaltMap,
          bumpMap: texAsphaltBump,
          bumpScale: 0.03,
          metalness: 0,
          roughness: 0.8,
        })
      ];
      
      const mesh = new Mesh(geo, mat);
      mesh.matrixAutoUpdate = false;
      mesh.name = "ground";
      mesh.layers.set(16);
      container.add(mesh);
    }

    return {container, layer};
  }
}
/*
  Prec. Codes | Description             | Decimal Places | Byte Length
  ----------------------------------------------------------------
  000:        | Same as last vertex     |      -         |     0
  001:        | Absolute low precision  |      3         |     2
  010:        | Absolute med. precision |      4         |     3
  011:        | Absolute big value      |      2         |     3
  100:        | Relative + mm offset    |      3         |     1
  101:        | Relative - mm offset    |      3         |     1
  110:        | Relative + cm offset    |      2         |     1
  111:        | Relative - cm offset    |      2         |     1
*/
function getValueAndOffset(bytes: Uint8Array, precCode: number, valueStartIndex: number, lastValue: number, origin: number, ignoreLast = false) {
  // Same Value
  const i = valueStartIndex;
  switch (precCode) {
    case 0: { return [lastValue, 0] }
    case 1: {
      const value = (bytes[i] + (bytes[i+1] << 8)) / 1000 + origin;
      return [value, 2];
    }
    case 2: {
      const value = (bytes[i] + (bytes[i+1] << 8) + (bytes[i+2] << 16)) / 10000 + origin;
      return [value, 3];
    }
    case 3: {
      const value = (bytes[i] + (bytes[i+1] << 8) + (bytes[i+2] << 16)) / 100 + origin;
      return [value, 3];
    }
    case 4: {
      const value = bytes[i] / 1000 + lastValue;
      return [value, 1];
    }
    case 5: {
      const value = -bytes[i] / 1000 + lastValue;
      return [value, 1];
    }
    case 6: {
      const value = bytes[i] * 0.01 + lastValue;
      return [value, 1];
    }
    case 7: {
      const value = -bytes[i] * 0.01 + lastValue;
      return [value, 1];
    }
  }
  console.log("Wrong precision Code in Input", precCode)
  return [0, 0];
}

function decodeVertices(data: string) {
  // Get Encoded Bytes
  const bytes = getEncodedBytes(data);
  const len = bytes.length;
  // Get Vertices
  const sXMask = 0b100000;
  const sYMask = 0b010000;
  const sZMask = 0b001000;
  const result : number[] = [];

  // Origin
  const dataCodeX = bytes[0] & 0b111;
  const dataCodeZ = bytes[1] & 0b111;
  const dataCodeY = (bytes[1] >>> 3) & 0b111;
  const signX = (bytes[0] & sXMask) === sXMask ? -1 : 1;
  const signY = (bytes[0] & sYMask) === sYMask ? -1 : 1;
  const signZ = (bytes[0] & sZMask) === sZMask ? -1 : 1;

  let i = 2;
  const oXData = getValueAndOffset(bytes, dataCodeX, i, 0, 0);
  i += oXData[1];
  const oYData = getValueAndOffset(bytes, dataCodeY, i, 0, 0);
  i += oYData[1];
  const oZData = getValueAndOffset(bytes, dataCodeZ, i, 0, 0);
  i += oZData[1];

  const oX = oXData[0] * signX;
  const oY = oYData[0] * signY; 
  const oZ = oZData[0] * signZ;

  let lastX = Infinity;
  let lastY = Infinity;
  let lastZ = Infinity;
  while (i < len) {
    const dataByte = bytes[i];
    const dataCodeX = (dataByte >>> 5) & 0b111;
    const dataCodeY = (dataByte >>> 3) & 0b11;
    const dataCodeZ = dataByte & 0b111;

    i += 1;
    const xData = getValueAndOffset(bytes, dataCodeX, i, lastX, oX);
    i += xData[1];
    const yData = getValueAndOffset(bytes, dataCodeY, i, lastY, oY);
    i += yData[1];
    const zData = getValueAndOffset(bytes, dataCodeZ, i, lastZ, oZ);
    i += zData[1];

    result.push(xData[0], yData[0], zData[0]);
    lastX = xData[0];
    lastY = yData[0];
    lastZ = zData[0];
  }
  const vecs = [];
  for (let i = 0; i < result.length / 3; i+= 3) {
    vecs.push({x: result[i], y: result[i+1], z: result[i+2]})
  }
  return result;
}

function getEncodedBytes(data: string) {
  const binaryString =  window.atob(data);
  const len = binaryString.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++)        {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

function decodeUVs(data: string) {
  // Get Encoded Bytes
  const bytes = getEncodedBytes(data);
  // Get Vertices
  let i = 0;
  const result : number[] = [];
  let lastU = 0;
  let lastV = 0;
  while (i < bytes.length) {
    const dataByte = bytes[i];
    const pU = (dataByte >>> 5) & 0b111;
    const pV = (dataByte >>> 2) & 0b111;
    const sU = ((dataByte >>> 1) & 0b1) === 1 ? -1 : 1;
    const sV = (dataByte & 0b1) === 1 ? -1 : 1;
    i += 1;
    const uData = getValueAndOffset(bytes, pU, i, lastU, 0);
    i += uData[1];
    const vData = getValueAndOffset(bytes, pV, i, lastV, 0);
    i += vData[1];

    result.push(uData[0] * sU, vData[0] * sV);
    lastU = uData[0];
    lastV = vData[0];
  }
  return result;
}