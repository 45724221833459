import React from 'react';
import logo from './logo.svg';
import { Scene, PerspectiveCamera, WebGLRenderer, Mesh, Color, ReinhardToneMapping, HalfFloatType, DataTexture, EquirectangularReflectionMapping, Clock, Object3D, BufferGeometry, Box3, Vector3, MeshBasicMaterial, TextureLoader, SRGBColorSpace } from "three";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import CameraControls from 'camera-controls';
import Tools from './Tools';
import * as THREE from "three";
import { C3dWebLoader } from './Loader/C3dWebLoader';
import { SkyBoxGeometry } from './Geometry/SkyboxGeometry';
import Menu from './Menu';
import { parse } from 'search-params';

import './App.css';
import { getUserLang, t } from './Translation';
import Impressum from './Impressum';
import Help from './Help';

export type Views = {view1: string, view2: string, view3: string, view4: string}

let initPos = new Vector3();
let initTarget = new Vector3();
let boundingBox = new Box3();
let renderer : WebGLRenderer;
let controls : CameraControls;
let camera : PerspectiveCamera;
let scene: Scene;
let lightLoaded = false;
let textureLoaded = false;
const serverUrl = window.location.hostname === "localhost" ? "localhost:3500" : "https://viewer-backend-jbj9m.ondigitalocean.app";

export type ErrorKey = "noId" | "wrongId" | "notFoundId" | "http";

let zDir = -1;

// Manual Render
const render = () => {
  if (renderer && camera && scene) {
    renderer.render(scene, camera);
  }
}

// Init
const init = (
    container: HTMLDivElement,
    renderer: WebGLRenderer,
    onTextureLoad: () => void,
    onLayerLoad: (layer: boolean[]) => void,
    projectId: string,
    onDataReceived: (data: {camosId: string, date: string}, views: Views) => void,
    onError: (value: ErrorEvent) => void,
    onLanguageReceived: (value: string) => void,
  ) => {

  // Scene and Camera
  scene = new Scene();
  scene.background = new Color(0xeeeeee);
  const headerSize = 125;
  camera = new PerspectiveCamera( 75, window.innerWidth / (window.innerHeight - headerSize), 0.1, 9000 );
  renderer.setSize( window.innerWidth, window.innerHeight - headerSize);
  renderer.toneMapping = ReinhardToneMapping;
  renderer.outputColorSpace = SRGBColorSpace;
  renderer.setPixelRatio(window.devicePixelRatio);
  container.appendChild( renderer.domElement );

  // Enable all Layers
  camera.layers.enableAll();

  // HDRI Light
  new RGBELoader()
    .setDataType(HalfFloatType)
    .load(`data/HDRI/realistisch1.hdr`, function (texture : DataTexture ) {
      texture.mapping = EquirectangularReflectionMapping;
      scene.environment = texture;
      lightLoaded = true;
      if (textureLoaded) { onTextureLoad() }
  });

  // Skybox
  const mat = new MeshBasicMaterial({
    map: new TextureLoader().load(`data/SkyBox/cubemap_1024.jpg`),
  });
  const skyBox = new Mesh(SkyBoxGeometry(9000), mat).rotateX(Math.PI / 2);
  skyBox.name = "sky";
  skyBox.updateMatrix();
  scene.add(skyBox);

  CameraControls.install({THREE});
  const clock = new Clock();
  controls = new CameraControls( camera, renderer.domElement );

  // Load Geometry
  if (projectId) {
    const url = `${serverUrl}/projects/${projectId}`;
    const loader = new C3dWebLoader();
    loader.load(url, (data, header, views) => {
        onDataReceived(header, views);
        scene.add(data);
        zDir = loader.zDir;
        setCameraPos(loader.zDir);
      }, 
      undefined,
      onError,
      () => {
        renderer.render(scene, camera);
        onTextureLoad();
      },
      onLayerLoad,
      onLanguageReceived,
    );
  } else if (window.location.host.startsWith("localhost")) {
    // Development
    import('./Loader/TestFiles').then(module => {
      const loader = new C3dWebLoader();
      loader.loadFromString(module.TestJSON, (data, header, views) => {
          onDataReceived(header, views);
          scene.add(data);
          zDir = loader.zDir;
          setCameraPos(loader.zDir);
        }, () => {
          renderer.render(scene, camera);
          onTextureLoad();
        },
        onLayerLoad,
        undefined,
        onError,
        onLanguageReceived,
      );
    });
  } else {
    onError(new ErrorEvent("noId"));
  }

  // Set Camera Position
  function setCameraPos(zDir: number) {
    const box = new Box3(new Vector3(), new Vector3());
    scene.traverseVisible((o: Object3D) => {
      if (o instanceof Mesh && o.name !== "ground" && o.name !== "sky") {
        o.updateMatrixWorld();
        const geo: BufferGeometry = o.geometry;
        geo.computeBoundingBox();
        const objBox = geo.boundingBox?.clone().applyMatrix4(o.matrixWorld) ?? new Box3();
        box.union(objBox);
      }
    });
    initPos = new Vector3(box.max.x + 9, 3, zDir === -1 ? -box.min.z + 9 : box.max.z + 9);
    initTarget = new Vector3((box.max.x + box.min.x) / 2, 1.5, zDir * (box.max.z + box.min.z) / 2);
    boundingBox = box; 
    controls.setPosition(...initPos.toArray());
    controls.setTarget(...initTarget.toArray());
    renderer.render(scene, camera);
  }

  // Controls
  controls.maxDistance = 500;
  controls.maxPolarAngle = Math.PI / 2;
  controls.setTarget(0, 0.5, 0);
  controls.dollySpeed = 0.4;

  // Resize
  window.addEventListener("resize", () => {
    const app = document.getElementById("app");
    if (app) {
      if (window.innerHeight >= 500) {
        renderer.setSize(app.clientWidth, app.clientHeight - headerSize, false);
        camera.aspect = app.clientWidth / (app.clientHeight - headerSize);
      } else {
        renderer.setSize(app.clientWidth - 60, app.clientHeight, false);
        camera.aspect = (app.clientWidth - 60) / app.clientHeight;
      }
    }
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
  }, false);

  // Animate
  (function anim () {
    const delta = clock.getDelta();
    const hasControlsUpdated = controls.update(delta);
    requestAnimationFrame(anim);
    if (hasControlsUpdated) {
      renderer.render(scene, camera);
    }
  })();

  return {controls, camera};
}

// Cleanup
const dispose = (renderer: WebGLRenderer) => {
  renderer.domElement.remove();
  renderer.dispose();
}

const onElevation = (side: string) => {
  if (controls) {
    const target = controls.getTarget(new Vector3());
    const pos = new Vector3(0, 1.5, 0);
    switch (side) {
      case "n":
        pos.x = boundingBox.min.x - 1;
        pos.z = zDir * ((boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z);
        break;
      case "s":
        pos.x = boundingBox.max.x + 1;
        pos.z = zDir * ((boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z);
        break;
      case "w":
        pos.x = (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x;
        pos.z = zDir * boundingBox.max.z - 1;
        break;
      case "e":
        pos.x = (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x;
        pos.z = zDir * boundingBox.min.z + 1;
        break;
    }
    controls.setLookAt(...pos.toArray(), ...target.toArray(), true);
    controls.fitToBox(new Box3(new Vector3(boundingBox.min.x, boundingBox.min.y, zDir * boundingBox.max.z), new Vector3(boundingBox.max.x, boundingBox.max.y, zDir * boundingBox.min.z)), true);
  }
}

const onZoomStart = () => {
  if (controls) {
    controls.setLookAt(...initPos.toArray(), ...initTarget.toArray(), true);
  }
}

function App() {
  const mountRef = React.useRef<HTMLDivElement>(null);
  const [controls, setControls] = React.useState<CameraControls | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [layer, setLayer] = React.useState<boolean[]>(Array.apply(null, Array(32)).map(() => false));
  const [expanded, setExpanded] = React.useState("none");
  const [checked, setChecked] = React.useState<boolean[]>(Array.apply(null, Array(32)).map(() => true));
  const [translation, setTranslation] = React.useState({});
  const [translationLang, setTranslationLang] = React.useState("");
  const [header, setHeader] = React.useState({camosId: "", date: ""});
  const [views, setViews] = React.useState({view1: "", view2: "", view3: "", view4: ""});
  const [overlay, setOverlay] = React.useState("none");
  const [error, setError] = React.useState("");

  const onChangeLayer = (layers: number[], value: boolean, index: number) => {
    for (const layer of layers) {
      if (!value) { camera.layers.enable(layer) }
      else { camera.layers.disable(layer) }
    }
    render();
    const newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
  }

  const loadTranslation = (lang = "de", callBack?: (trans: Object) => void) => {
    // Load Translation
    const translationUrl = `${serverUrl}/translationValues/${lang}`;
    fetch(translationUrl, {
      method: "GET",
    }).then(res => {
      if (res?.ok) {
        res.json().then(json => {
          setTranslation(json);
          setTranslationLang(json.lang);
          if (callBack) { callBack(json) }
        });
      } else {
        console.log(`HTTP error when loading Translation: ${res?.status}`);
      }
    });
  }

  const onDataReceived = (data: {camosId: string, date: string}, v: {view1: string, view2: string, view3: string, view4: string}) => {
    setHeader(data);
    setViews(v);
  }

  const onLanguageReceived = (lang: string) => {
    loadTranslation(lang);
  }

  // Forces Rerender after Translation file is loaded
  React.useEffect(() => {
  }, [translationLang]);

  // Inits the project
  React.useEffect(() => {
    const container = mountRef.current;
    const options = {
      powerPreference: "high-performance",
	    stencil: false,
      alpha: true,
      antialias: true,
      logarithmicDepthBuffer: false,
      preserveDrawingBuffer: false,
    };
    renderer = new WebGLRenderer(options);
    const projectData : {id: string} = parse(window.location.search);
    if (!container) {
      throw new Error("ThreeView Container has no reference!");
    }

    const onError = (value: ErrorEvent) => {
      const lang = getUserLang();
      const handleError = (trans?: Object) => {
        const tr = trans ?? translation;
        const errorMap : {[Property in ErrorKey]: string} = {
          noId: t(tr, "err_noId"),
          wrongId: t(tr, "err_wrongId"),
          notFoundId: t(tr, "err_notFoundId"),
          http: `${t(tr, "err_http")} ${value.message} - ${t(tr, "err_again")}`,
        };
        setError(errorMap[value.type as keyof typeof errorMap]);
      }
      // Load Translation if not loaded yet
      if ((translation as any).lang == null) {
        loadTranslation(lang, handleError);
      } else {
        handleError();
      }
    }

    const onTextureLoad = () => {
      if (lightLoaded || textureLoaded) { setLoading(false) }
      textureLoaded = true;
    }

    const c = init(container, renderer, onTextureLoad, layer => setLayer(layer), projectData.id, onDataReceived, onError, onLanguageReceived);
    setControls(c.controls);

    // Cleanup Function
    return () => {
      dispose(renderer);
    };
  /*eslint-disable-next-line*/
  }, []);

  return (
    <div id="app" className="app">
      <header className="appHeader">
        <Menu
          setExpandedPanel={(value) => setExpanded(value)}
          expandedPanel={expanded} trans={translation}
          setOverlayPanel={value => setOverlay(value)}
          setLanguage={onLanguageReceived}
        />
        <img src={logo} className="appLogo" alt="logo" />
        <h1 className={`${(translation as any).title ? "" : "hidden"} viewer`}>{t(translation, "title")}</h1>
        <div className={`${loading ? "hidden" : ""} title`}>
          <h1 className="grey">{`${t(translation, "title_project")}`}</h1>
          <h1>{header.camosId}</h1>
          <h2 className="grey">{t(translation, "title_created")}:</h2>
          <h2>{header.date}</h2>
        </div>
      </header>
      <div className={`loading ${loading ? "" : "hidden" }`}>
        <div>
          <span className={`loader ${error ? "hidden" : ""}`}></span>
          <p className={error ? "error" : ""}>{`${error !== "" ? error : t(translation, "loading")}`}</p>
        </div>
      </div>
      <div onClick={() => setExpanded("none")} className="modelContainer" ref={mountRef}>
      </div>
      <Impressum visible={overlay === "impressum"} trans={translation} setOverlay={setOverlay}/>
      <Help visible={overlay === "help"} trans={translation} setOverlay={setOverlay}/>
      <Tools
        controls={controls}
        onElevation={onElevation}
        onZoomStart={onZoomStart}
        onChangeLayer={onChangeLayer}
        layer={layer}
        expandedPanel={expanded}
        setExpandedPanel={setExpanded}
        checked={checked}
        trans={translation}
        views={views}
      />
    </div>
  );
}

export default App;